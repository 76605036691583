/**
 * 不同设备小程序二维码地址配置
 */
export default {
    /**
     * 充电桩正式服二维码地址
     */
    chargePile:"https://www.nuoyayunpingtai.com/official?chargeNum=", // 汽车
    batteryChargePile:"https://www.nuoyayunpingtai.com/official?batteryChargeNum=", // 两轮电瓶车

    /**
     * 充电桩测试服二维码地址
     */
    testChargePile:"https://test.nuoyayunpingtai.com/test?chargeNum=", // 汽车
    testBatteryChargePile:"https://test.nuoyayunpingtai.com/test?batteryChargeNum=", // 两轮电瓶车

    /**
     * 充放电柜正式服二维码地址
     */
    chargeDischarge:"https://www.nuoyayunpingtai.com/chargeDischarge?devId=",

    /**
     * 充放电柜测试服二维码地址
     */
    testChargeDischarge:"https://test.nuoyayunpingtai.com/testChargeDischarge?devId=",
}