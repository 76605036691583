/**
* 分页组件
*/ 
<template>
  <!--分页-->
  <el-col :span="24" class="toolbar pagination" >
    <el-pagination
    background
      layout="total, prev, pager, next, jumper"
      @current-change="handleCurrentChange"
      :page-size="10"
      :total="childMsg.total"
      :current-page="childMsg.pageNo"
      style="float:right;"
    ></el-pagination>
  </el-col>
</template>
<script>
export default {
  name: 'Pagination',
  props: ['childMsg'],
  data() {
    return {
      pageparm: {
        currentPage: this.childMsg.currentPage,
        pageSize: this.childMsg.pageSize
      }
    }
  },
  watch:{
    childMsg: { // 监听请求数据时，当前页码是否发生改变，若发生变化，则分页组件显示的当前页码也应随之改变
        deep: true,
        handler (val, oldVal) {
            if(val !== oldVal){
                this.childMsg={...val};
                this.handleCurrentChange();
            }
        }
    }
  },
  created() {},
  methods: {
    handleSizeChange(val) {
      /**
       * 子传父
       * 参数1 父元素方法
       * 参数2 数据
       */
      this.pageparm.pageSize = val
      this.$emit('callFather', this.pageparm)
    },
    handleCurrentChange(val) {
      /**
       * 子传父
       * 参数1 父元素方法
       * 参数2 数据
       */
      this.pageparm.currentPage = val
      this.$emit('callFather', this.pageparm)
    }
  }
}
</script>

<style>
.page-box {
  margin: 10px auto;
  float: right;
}
</style>
