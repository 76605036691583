import Vue from 'vue'

// 校验版本号
export const validateVersion = (rule, value, callback) => {
  let reg = /^(V)([1-9]\d|[1-9])(.([1-9]\d|\d)){2}$/;
  if (reg.test(value)) {
    callback();
  } else {
    callback(new Error('请填写以V开头形式正确的版本号，例：V1.1.2'));
  }
}

// 详细地址转换成GPS
export const getPointByAddress = (address) => {
  // 创建地理编码实例
  const myGeo = new BMapGL.Geocoder();
  return new Promise((resolve,reject) => { // 通过Promise将回调函数从异步改为同步
    myGeo.getPoint(address, (point) => {
      if (point) {
        // 地理编码成功，返回经纬度坐标对象
        resolve(point);
      } else {
        // 地理编码失败
        reject('地理编码失败');
      }
    }, '全国');
  })
}

// 递归循环，去除childrenList为空时的该属性
export function removeChildren(tree){
  if(tree.childrenList && tree.childrenList.length == 0){
    Vue.delete(tree,'childrenList'); // 去除某个属性
    return tree;
  }else{
    tree.childrenList.forEach(element=>{
      removeChildren(element);
    })
  }
}
// 获取联动选择器回显文字
export function getCascaderLabel(id,arr,text){
  if(id.length == 0){
    return text;
  }else{
    for(let i=0;i<arr.length;i++){
      if(arr[i].id == id[0]){
        text = text + arr[i].name;
        id.splice(0,1);
        return getCascaderLabel(id,arr[i].childrenList,text);
      }
    }
  }
}

// 获取省市区多选联动框回显文字
export function getPCA(pcaOptions,choosedItem){ // (省市区选项，选中的id)
  let pca = {
    city:'',
    area:'',
    street:'',
  }
  for(let i=0;i<pcaOptions.length;i++){
    if(pcaOptions[i].value == choosedItem[0]){
      pca.city = pcaOptions[i].label;
      let children = pcaOptions[i].children;
      for(let k=0;k<children.length;k++){
        if(children[k].value == choosedItem[1]){
          pca.area=children[k].label;
          let child = children[k].children;
          for(let j=0;j<child.length;j++){
            if(child[j].value == choosedItem[2]){
              pca.street = child[j].label;
              return pca;
            }
          }
          break;
        }
      }
      break;
    }
  }
}

// 时间戳转时间
export function timeToTime (time) {
    const date = new Date(Number(time))
    const Y = date.getFullYear() // 年
    const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
    const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
    const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
    const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
    const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
    const Time={
        year:Y,
        month:M,
        day:D,
        hour:H,
        minutes:m,
        seconds:S,
    };
    return Time;
}

// 中国标准时间转年月日
export function timeChange (time,type) {
  var chinaStandard = time;
    var date = new Date(chinaStandard);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    var minute = date.getMinutes();
    minute = minute < 10 ? ('0' + minute) : minute;
    var second = date.getSeconds();
    second = second < 10 ? ('0' + second) : second;
    let Time;
    if(type == 'day'){
      Time = y + '-' + m + '-' + d;
    }else if(type == 'month'){
      Time = y + '-' + m;
    }else if(type == 'minutes'){
      Time = `${y}-${m}-${d} ${h}:${minute}`;
    }else if(type == 'hour'){
      Time = `${y}-${m}-${d} ${h}:00:00`;
    }else if(type == 'seconds'){
      Time = `${y}-${m}-${d} ${h}:${minute}:${second}`;
    }else{
      Time = {
        year:y,
        month:m,
        day:d,
        hour:h,
        minutes:minute,
        seconds:second,
      }
    }
    return Time;
}

// 年月日转中国标准时间
export function changeToStandardTime(time){
  const dateArr = time.split('-');
  const year = parseInt(dateArr[0]);
  const month = parseInt(dateArr[1]) - 1; // 月份从 0 开始，需要减 1
  const day = parseInt(dateArr[2]);
  const chinaTime = new Date(Date.UTC(year, month, day)); // 使用 UTC 时间创建 Date 对象
  chinaTime.setHours(chinaTime.getHours() + 8); // 调整为中国时区
  return chinaTime;
}

// 根据总秒数转换成 --时--分--秒
export function secondToMinite(mins){
  let hour = 0,min = 0;
  hour = parseInt(mins / 60);
  min = mins % 60;
  return {
    hour,
    min,
  }
}

// 获取当前时间
export function getNowTime(type){
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = (now.getMonth() + 1) < 10 ? '0'+(now.getMonth() + 1) : now.getMonth() + 1; // 月份从 0 开始，需要加 1
  const currentDay = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();
  const currentHour = now.getHours() < 10 ? `0${now.getHours()}` : now.getHours();
  const currentMinute = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
  const currentSecond = now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds();
  if(type == 'year'){
    return currentYear;
  }else if(type == 'month'){
    return `${currentYear}-${currentMonth}`;
  }else if(type == 'day'){
    return `${currentYear}-${currentMonth}-${currentDay}`;
  }else if(type == 'hour'){
    return `${currentYear}-${currentMonth}-${currentDay} ${currentHour}:00:00`
  }else if(type == 'minutes'){
    return `${currentYear}-${currentMonth}-${currentDay} ${currentHour}:${currentMinute}:00`
  }else if(type == 'seconds'){
    return `${currentYear}-${currentMonth}-${currentDay} ${currentHour}:${currentMinute}:${currentSecond}`
  }else{
    return {
        year:currentYear,
        month:currentMonth,
        day:currentDay,
        hour:currentHour,
        minutes:currentMinute,
        seconds:currentSecond,
    }
  }
}

// 表单验证
// 单时预约量必须大于零
export function MakeMaxNumRule (rule, value, callback) {
  if (value <= 0) {
    callback(new Error('该数字须大于零'));
  } else {
    callback();
  }
};

// 手机号码验证
export function phoneRight (rule, value, callback) {
  let phoneRule = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  let telephoneRegex = /^([0-9]{3,4}-)?[0-9]{7,8}$/;
  if (phoneRule.test(value) || telephoneRegex.test(value)) {
    callback();
  } else {
    callback(new Error('请输入正确的联系方式'));
  }
}

// 纯数字
export function onlyNumber(rule,value,callback){
  let reg = /^\d+$/;
  //判断是否是正则表达式的格式
  let flag = reg.test(Number(value));
  if(!flag){
    callback(new Error('必须为纯数字'));
  }else {
    callback();
  }
}

// 单时预约量必须为正整数
export function positiveInteger (rule, value, callback) {
  const reg = /^(0|[1-9]\d*)$/; // 匹配整数的正则表达式
  if (!reg.test(value)) {
    callback(new Error('该数字须大于等于零，且为整数'));
  } else {
    callback();
  }
};

// 电瓶车充电桩最大功率限制不得超过2000w
export function maxpower (rule, value, callback) {
  const reg = /^(0|[1-9]\d*)$/; // 匹配整数的正则表达式
  if (!reg.test(value) || value > 2000) {
    callback(new Error('该数字须为整数,范围为：0~2000'));
  } else {
    callback();
  }
}

// 卡折扣0-100
export function discount (rule, value, callback) {
  const reg = /^(0|[1-9]\d*)$/; // 匹配整数的正则表达式
  if (!reg.test(value) || value > 100) {
    callback(new Error('该数字须为整数,范围为：0~100'));
  } else {
    callback();
  }
}